.header {
    width: 100%;
    height: 50px;
    position: absolute;
    top: %;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Shadows Into Light Two', cursive;
    font-size: 50px;
    font-weight: bold;
    text-shadow: .5px .5px .5px #9c8873;
    color: white;
    letter-spacing: 2px;
    padding: 100px 0px 20px 0px;
}

.header-container {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0px 10px 10px 0px;
}

.logo {
    width: 30px; 
    height: auto;
    z-index: 1;
    margin: 10px 0px 0px 10px;
    align-items: center;
}

.image-container {
    max-width: 100%;
    position: relative;
}

.retreat-image-container {
    flex-shrink: 0; /* Prevent image from shrinking */
    margin-right: 10px; /* Add margin to separate the image from text */
    margin-top: 10px; /* Add margin above the image */
    margin-left: 10px; /* Add margin to the left of the image */
    display: flex; /* Use flexbox */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
}

.image {
    width: 100%;
    height: auto;
    object-fit: cover;
    margin-top: -50px;
}

.retreat-image {
    width: 40vw; /* Adjust the width as needed */
    height: auto;
}

.social {
    width: 100vw;
    background: #ffffff;
    margin-top: -5px;
    padding: 20px 20px 50px 0px;
    text-align: center;
}

.retreat {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.text-container {
    flex-grow: 1; /* Allow text to grow to fill remaining space */
}

.text {
    font-family: 'Inter', sans-serif;
    color: #66533e; /* Adjust text color as needed */
    font-size: 18px; /* Adjust font size as needed */
}

.retreat-text {
    font-family: 'Inter', sans-serif;
    color: #66533e; /* Adjust text color as needed */
    font-size: 18px; /* Adjust font size as needed */
    transition: transform 0.3s;
}

.retreat-text:hover {
    transform: scale(1.3); 
}

.details-button {
    display: inline-block; /* Ensure it behaves like a block element */
    padding: 10px 20px; /* Adjust padding as needed */
    background-color: #66533e; /* Set button background color */
    color: white; /* Set button text color */
    text-decoration: none; /* Remove underline */
    border: none; /* Remove border */
    border-radius: 10px; /* Add border radius for rounded corners */
    font-family: 'Inter', sans-serif; /* Specify font family */
    font-size: 16px; /* Adjust font size as needed */
    cursor: pointer; /* Change cursor to pointer on hover */
    transition: background-color 0.3s, color 0.3s; /* Add transition effect */
}

.details-button:hover {
    background-color: #b5a391; /* Change background color on hover */
}

.headline-brown {
    font-family: 'Shadows Into Light', cursive;
    font-size: 30px; 
    color: #66533e;
    letter-spacing: 1px;
    text-shadow: .5px .5px .5px #ffffff;
}

.headline-white {
    font-family: 'Shadows Into Light', cursive;
    font-size: 30px;
    color: white;
    letter-spacing: 1px;
    padding: 0px 20px 0px 20px;
    font-weight: bold;
}

.text-white {
    font-family: 'Inter', sans-serif;
    display: block;
    color: white;
    font-size: 18px;
    padding: 10px 20px 0px 20px;
    margin-bottom: 10px;
    text-align: center; 
}

.text-brown {
    font-family: 'Inter', sans-serif;
    display: block;
    color: #9c8873;
    font-size: 18px;
    padding: 0px 20px 20px 20px;
    margin-bottom: 10px;
    text-align: center; 
}

.text-brown-center {
    font-family: 'Inter', sans-serif;
    display: block;
    color: #9c8873;
    font-size: 18px;
    padding: 0px 50px 20px 50px;
    margin-bottom: 10px;
    text-align: center; 
}

.footer {
    background-color: #b5a391;
    padding: 20px 0px 15px 0px;
}

.footertext-white {
    font-family: 'Shadows Into Light Two', cursive;
    color: white;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 3px;
    padding: 0px 50px 0px 50px;
}

.footertext-legal {
    font-family: 'Shadows Into Light Two', cursive;
    color: #decab6;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    padding: 0px 50px 0px 50px;
}

.bulleted-list {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    font-family: 'Inter', sans-serif;
    color: white;
    font-size: 16px;
    padding: 10px 20px 20px 20px;
    margin-left: 20px;
    margin-bottom: 10px;
}

.bulleted-list li {
    margin-bottom: 10px;
    text-align: left;
}

.letters {
    font-weight: bold;
    letter-spacing: 10px;
    font-size: 25px;
    margin-right: 5px;
}

.center {
    display: flex;
    justify-content: center;
}

.icon-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.icon {
    width: 30px;
    margin: 0 10px;
    transition: transform 0.3s;
}

@media screen and (max-width: 768px) {
    .icon-container {
        flex-direction: column;
        align-items: center;
    }

    .icon-row {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px 0; /* Add margin between rows for spacing */
    }
}

.icon:hover {
    transform: scale(1.3); 
}

.retreat-icon {
    width: 30vw;
    margin: 10px 10px 0 10px;
    transition: transform 0.3s;
}

.retreat-icon:hover {
    transform: scale(1.1); 
}

.input {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.input-text {
    width: 80%;
    padding: 8px;
    margin-bottom: 20px;
}

.button-schedule {
    background-color: #9c8873 !important;
    font-family: 'Shadows Into Light Two', cursive;
    font-size: 25px;
    color: white;
    padding: 10px; 
    margin-left: 10px;
    border: 1px solid #ddd;
    border-radius: 6px;
    box-shadow: 0 3px 10px #ccc;
    transition: background-color 0.3s, color 0.3s, transform 0.3s;
}

.button-schedule:hover {
    background-color: #b5a391 !important;
    color: white;
    transform: scale(1.1);
}

.padding {
    background-color: #b5a391;
    padding: 10px 10px;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.hyperlink {
    color: #66533e;
    text-decoration: none;
    transition: color 0.3s; 
}

.hyperlink:hover {
    color: #b5a391;
}